/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'process': {
    width: 18,
    height: 19,
    viewBox: '0 0 18 19',
    data: '<g transform="translate(1.047 1)" _stroke="#1D1D44" _fill="none" fill-rule="evenodd"><circle pid="0" cx="2.667" cy="1.871" r="1.871"/><path pid="1" d="M12.585 6.737L10.8 8.523l1.786 1.786"/><path pid="2" d="M4.632 1.895h6.643a3.368 3.368 0 013.368 3.368v.315a3.053 3.053 0 01-3.053 3.054h0-.424"/><circle pid="3" cx="11.977" cy="15.392" r="1.871" transform="rotate(180 11.977 15.392)"/><path pid="4" d="M2.058 10.527L3.845 8.74 2.06 6.954"/><path pid="5" d="M10.106 15.368H3.369A3.368 3.368 0 01.001 12v-.315a3.053 3.053 0 013.053-3.053h.424"/><circle pid="6" cx="7.298" cy="8.608" r="1.871" transform="matrix(-1 0 0 1 14.596 0)"/></g>'
  }
})
